import React from "react"
import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"

import { SectionSlideBar } from "../../components/PageDevelopers/SectionSlideBar/SectionSlideBar"
import { SectionMenu } from "../../components/PageDevelopers/SectionMenu/SectionMenu"
import { SectionContainer } from "../../components/PageDevelopers/SectionContainer/SectionContainer"
import { SectionPostman } from "../../components/PageDevelopers/SectionPostman/SectionPostman"

const Developers = () => {
  const dummyMenuSlideBar = [
    { id: 1, item: "Enviroment", toogle: "No enviroment" },
    { id: 2, item: "Layout", toogle: "Double column" },
    { id: 3, item: "Language", toogle: "cURL - cURL" },
  ]

  const dummyMenu = [
    { id: 1, title: "Introducción" },
    { id: 2, title: "Inicio y usuarios" },
    { id: 3, title: "Tipos de servicio" },
    { id: 4, title: "Servicios" },
    { id: 5, title: "Organización" },
    { id: 6, title: "Bolsa de entregas" },
    { id: 7, title: "Vehiculos de trabajadores" },
    { id: 8, title: "Webhooks" },
  ]

  const dummyList = [
    { id: 1, name: "Creada" },
    { id: 2, name: "Buscando conductor" },
    { id: 3, name: "Asignado" },
    { id: 4, name: "En Progreso" },
    { id: 5, name: "Finalizado" },
    { id: 6, name: "Cancelado" },
    { id: 7, name: "Cumplido" },
  ]

  return (
    <Layout section="section_ligth">
      <div className="developers">
        <div className="developers__container">
          <SectionSlideBar dummyMenu={dummyMenuSlideBar} />
          <div className="developers--content">
            <SectionMenu
              title="Liftit API Documentación pública"
              dummyMenu={dummyMenu}
            />
            <SectionContainer dummyList={dummyList} />
            <SectionPostman />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Developers
