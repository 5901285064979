import React from "react"
import { Button, Select } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import Typist from "react-typist"

import copy from "../../../assets/images/copy.png"

const { Option } = Select

export const SectionPostman = () => {
  const handleChange = value => {
    console.info(`selected ${value}`)
  }

  const keyOpen = "{"
  const keyClose = "}"
  const testUsernameJson = '"useremail@useremail.com"'
  const testPasswordJson = '"password": "userpassword"'

  return (
    <div className="section-postman">
      <div className="section-postman--head">
        <CloseOutlined />
        <Button className="button">Switch to console</Button>
      </div>
      <div className="section-postman-title">
        <h5>User log in / Get token</h5>
      </div>
      <div className="section-postman--api">
        <div className="api">
          <span>POST</span>
        </div>
        <p className="url">https://api.liftit.co/v1/service&&?</p>
        <img src={copy} className="copy" alt="copy" />
      </div>
      <div className="section-postman--api">
        <h4>Request</h4>
      </div>
      <div className="section-postman--options">
        <div className="selects">
          <Select
            defaultValue="lucy"
            style={{ minWidth: 110 }}
            onChange={handleChange}
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
          <Select
            defaultValue="lucy"
            style={{ minWidth: 110 }}
            onChange={handleChange}
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="Yiminghe">yiminghe</Option>
          </Select>
          <Button className="button">Try</Button>
        </div>
        <h4>Headers</h4>
      </div>
      <div className="section-postman--options">
        <div className="selects">
          <p className="selects--title">
            Content-Type:
            <span>application/json</span>
          </p>
        </div>
        <h4>Body</h4>
      </div>
      <div className="section-postman--options">
        <Typist>
          <p className="num">
            01 <span className="key">{keyOpen}</span>
          </p>
          <p className="num">
            02 <span className="text">{testUsernameJson},</span>
          </p>
          <p className="num">
            03 <span className="text">{testPasswordJson}</span>
          </p>
          <p className="num">
            04 <span className="key">{keyClose}</span>
          </p>
        </Typist>
      </div>
    </div>
  )
}
