import React from "react"

export const SectionContainer = ({ dummyList }) => {
  return (
    <div className="section-container">
      <h1 className="title">Liftit API Documentación pública</h1>
      <p>
        Liftit API es un servicio web RESTful que permite realizar integraciones
        con sistemas externos (Organizaciones) permitiendo que soliciten y
        administren servicios a través de la plataforma de Liftit.
      </p>
      <p>
        Un servicio es una colección de tareas alineadas para que el conductor
        las ejecute de una manera ordenada. Una vez el servicio a sido creado,
        puede agregar múltiples tareas antes se encuentre preparadas para ser
        ejecutar, de tal forma que pueda ser asignado al conductor.
      </p>
      <p>
        <span>Nota:</span>
        <br />
        Para propósitos de prueba , solo la API deberá ser ejecutada en nuestros
        servidores simulados proporcionados en la documentación, a continuación
        se muestra la URL del servidor simulado.
      </p>
      <p>
        <span>Flujo en la Creación de Servicios</span>
        <br />
        Registrar y crear un Token que permitie consumir la API.Como una
        organización, usted puede tener tantos admin y como usted considere
        necesario.
      </p>
      <p>
        Obtener sus tipos de servicios, usando cada Endpoints apropiadamente
        como se describe abajo.
      </p>
      <p>
        Realizar peticiones de servicio, recuerde enviar el tipo de servicio
        apropiado. Este servicio puede ser creado con tareas.
      </p>
      <p>Adjunte tareas al servicio si lo cree necesario.</p>
      <p>Ejecute el servicio.</p>
      <p>Algunos de los terminos ms usados en esta guia son:</p>
      <p>
        Servicios: Un servicio es compuesto por una o mas tareas. Un servicio
        puede estar en alguno de los siguiente estados.
      </p>
      <div className="list">
        {dummyList?.map((item, index) => (
          <ul key={index} className="list-item">
            <li className="text">
              {index} - {item.name}
            </li>
          </ul>
        ))}
      </div>
    </div>
  )
}
