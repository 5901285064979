import React from "react"
import { SettingFilled, CaretDownOutlined } from "@ant-design/icons"
import { Dropdown } from "antd"

export const SectionSlideBar = ({ dummyMenu }) => {
  const menu = (
    <div className="menu-slide-bar">
      <SettingFilled className="icon-settings" />
      {dummyMenu?.map((item, index) => (
        <div key={index} className="slide-bar--content">
          <h5 className="item">{item.item}</h5>
          <a className="toogle">{item.toogle}</a>
          <CaretDownOutlined />
        </div>
      ))}
    </div>
  )

  return (
    <div className="slider-bar">
      <Dropdown className="Dropdown" overlay={menu} trigger={["click"]}>
        <button
          type="button"
          className="ant-dropdown-link"
          onClick={e => e.preventDefault()}
        >
          Documentation settings <CaretDownOutlined />
        </button>
      </Dropdown>
      <div className="slide-bar">
        {dummyMenu?.map((item, index) => (
          <div key={index} className="slide-bar--content">
            <h5 className="item">{item.item}</h5>
            <a className="toogle">{item.toogle}</a>
            <div className="divider" />
          </div>
        ))}
        <SettingFilled />
      </div>
    </div>
  )
}
