import React, { useState } from "react"
import { Dropdown, message } from "antd"
import { MenuOutlined } from "@ant-design/icons"

function handleButtonClick(e) {
  message.info("Click on left button.")
  console.info("click left button", e)
}

export const SectionMenu = ({ title, dummyMenu }) => {
  const [itemActive, setItemActive] = useState("Introducción")

  const handleItemSelect = key => {
    setItemActive(key)
  }

  const menu = (
    <div className="menu-responsive-developers">
      <div className="cont-title">
        <h3 className="menu-title">{title}</h3>
      </div>
      <div className="items">
        {dummyMenu?.map((item, index) => (
          <div
            key={index}
            className="items__item"
            onClick={() => handleItemSelect(item.title)}
          >
            <h4
              className={`items__item--title ${
                item.title === itemActive && "items__item--title--active"
              }`}
            >
              {item.title}
            </h4>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      <div className="menu-responsive">
        <Dropdown.Button
          className="dropdown-button"
          onClick={handleButtonClick}
          overlay={menu}
          icon={<MenuOutlined />}
        >
          {itemActive}
        </Dropdown.Button>
      </div>
      <div className="section-menu">
        <div className="cont-title">
          <h3 className="menu-title">{title}</h3>
        </div>
        <div className="items">
          {dummyMenu?.map((item, index) => (
            <div
              key={index}
              className="items__item"
              onClick={() => handleItemSelect(item.title)}
            >
              <h4
                className={`items__item--title ${
                  item.title === itemActive && "items__item--title--active"
                }`}
              >
                {item.title}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
